import React from 'react'
import { InputGroup } from 'reactstrap'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import ButtonClose from 'components/ButtonClose'
import enCommon from 'locales/en/common.json'
import plCommon from 'locales/pl/common.json'
import Wrapper from './Wrapper'
import Input from './Input'
import Button from './Button'

const lngSearch = {
  en: enCommon.search,
  pl: plCommon.search,
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    overflow: 'none',
    position: 'absolute',
    zIndex: 99999,
  },
}

ReactModal.setAppElement('#___gatsby')

class SearchInput extends React.PureComponent {
  state = {
    search: '',
    modalOpen: false,
  }

  updateField = (event) => this.setState({ search: event.target.value })

  submitSearch = () => {
    const { search } = this.state

    if (search.length > 2) {
      window.open(`${process.env.ORION_SHOP}?phrase=${search}`, '_blank')
    }
  }

  searchOnEnter = (event) => {
    const ENTER = 13

    if (event.keyCode === ENTER) {
      this.openModal()
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  render () {
    const { search, modalOpen } = this.state
    const { language } = this.props

    return (
      <>
        <Wrapper>
          <div style={{ display: 'flex' }}>
            <Input
              placeholder={lngSearch[language].placeholder}
              value={search}
              onChange={this.updateField}
              onKeyDown={this.searchOnEnter}
            />
            <Button onClick={this.openModal} />
          </div>
        </Wrapper>
        <ReactModal
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          style={customStyles}
          contentLabel="Search Modal"
        >
          <div style={{ padding: '20px' }}>
            <ButtonClose onClick={this.toggleModal} />
            <h2>{lngSearch[language].shop}</h2>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              {lngSearch[language].shoptext}
            </div>
          </div>
        </ReactModal>
      </>
    )
  }
}

SearchInput.propTypes = {
  language: PropTypes.string,
}

export default SearchInput
