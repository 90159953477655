import styled from 'styled-components'
import {
  mobile,
  tablet,
} from 'styles/devices'

const Wrapper = styled.div`
  width: 50%;
  margin-left: 50%;
  margin-top: 15px;
  padding-bottom: 10px;

  img {
    // width: 80%;
    max-height: 42px;
  }

  @media ${tablet} {
    width: 50%;
    margin-left: 50%;
  }

  @media ${mobile} {
    width: 100%;
    margin-left: 0;
  }
`

export default Wrapper
